.Hero_container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  position: relative;
}

.left {
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.logo {
  padding: 0.5rem;
  position: absolute;
  top: 0;
  left: 20vw;
}
.logo2 {
  padding: 0.5rem;
  position: absolute;
  top: 0;
  right: 20vw;
}

.logo > img,
.logo2 > img {
  width: 10rem;
}

.socialId {
  right: 100px;
  bottom: 50px;
  position: absolute;
  gap: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.socialId > a > svg {
  padding: 5px;
  font-size: 2rem;
  transition: all 1s ease-out;
  color: rgb(7, 114, 228);
}

.socialId > a > svg:hover {
  color: rgb(245, 245, 248);
  background-color: rgb(7, 114, 228);
  border-radius: 5px;
}

.title > span {
  margin: 2rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  flex-direction: column;
  font-size: 2rem;
  font-weight: 700;
}

.title > span:nth-child(2) {
  color: rgb(61, 60, 60);
}
.title > span:nth-child(3) {
  color: rgb(107, 102, 102);
}

.Hero_container > .right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img_container {
  width: 15rem;
}
.Hero_container > .right > .img_container > img {
  width: 100%;
  padding: 2rem;
}

@media screen and (max-width: 900px) {
  .Hero_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1;
  }
  .left {
    margin-top: 150px;
    margin-bottom: 0;
  }

  .right {
    margin-bottom: 100px;
  }
  .right > img {
    margin-bottom: 100px;
  }
  .logo {
    padding: 0.5rem;
    position: absolute;
    top: 0;
    left: 5vw;
  }
  .logo2 {
    padding: 0.5rem;
    position: absolute;
    top: 0;
    right: 5vw;
  }
  .socialId {
    left: 75px;
    bottom: 0;
    margin-top: 50px;
    position: absolute;
    gap: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .socialId > a > svg {
    padding: 10px;
    font-size: 3rem;
    transition: all 1s ease-out;
    color: rgb(7, 114, 228);
  }
  .socialId > a > svg:hover {
    color: rgb(245, 245, 248);
    background-color: rgb(7, 114, 228);
    border-radius: 5px;
  }
}
