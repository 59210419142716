@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap");

:root {
  --color-bg: rgb(234, 234, 234);
  --color-dark: rgb(156, 153, 153);
  --color-light: rgb(255, 255, 255);
  --color-primary: rgb(3, 35, 116);
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  font-family: "Roboto", sans-serif;
  background: var(--color-bg);
}

::-webkit-scrollbar {
  display: none;
}
