.nav_container {
  display: block;
  position: fixed;
  width: max-content;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1.5rem;
  box-shadow: 5px 5px 5px rgba(7, 114, 228, 0.44);
  backdrop-filter: blur(5px);
  border-radius: 25px;
}
.nav_items {
  background: var(rgb(7, 114, 228));
  padding: 0.3rem 1rem;
  display: flex;
  gap: 0.8rem;
  cursor: pointer;
}

svg {
  font-size: 1.2rem;
}
li {
  padding: 0.5rem 0.5rem;
  border-radius: 25px;
  display: flex;
  align-items: center;
  transition: all 1s ease-out;
  background-color: rgb(7, 114, 228);
  color: rgb(249, 250, 251);
}

li:hover {
  background-color: rgb(245, 245, 246);
  color: rgb(7, 114, 228);
}
li.active {
  background-color: rgb(251, 252, 252);
  color: rgb(7, 114, 228);
}
